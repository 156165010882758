
class RoleService {

    getRoles() {
        let roles = localStorage.getItem("roles");

        if (!roles) { return []}
        console.log("Roles: " + roles)
        return roles.split(",");
    }

    /**
     * Check if user has a specified role
     * @param role
     */
    userHasRole(role) {
        const savedRoles = this.getRoles();
        return savedRoles.includes(role);
    }

    userHasOneRole(allowedRoles) {
        const savedRoles = this.getRoles();

        console.log("saved roles: " + savedRoles);
        console.log("shown roles: " + allowedRoles);

        for (const role of allowedRoles) {
            if (savedRoles.includes(role)) {
                return true;
            }
        }
        return false;
    }

}

export default new RoleService();