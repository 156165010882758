import store from "@/state/store";
import router from "@/router/index";
import AuthService from "@/services/AuthService";

export default [
  {
    path: "/",
    name: "default",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/pages/home")
  },
  {
    path: "/login",
    name: "login",
    component: () => import("./views/account/login"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/mlogin",
    name: "mlogin",
    component: () => import("./views/account/mobileid-login-composition"),
    meta: {
      authRequired: false,
    }
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        AuthService.confirmLogoutPopup();
      },
    },

  },
  {
    path: "/404",
    name: "404",
    component: require("./views/utility/404").default,
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  {
    path: "/:catchAll(.*)",
    redirect: "404",
  },
  {
    path: "/test",
    name: "test",
    meta: { authRequired: true },
    component: () => import("./views/pages/home"),
  },
  {
    path: "/pages/sim-provisioning",
    name: "SIM Provisioning",
    meta: { authRequired: true },
    component: () => import("./views/pages/sim/sim-provisioning"),
  },
  {
    path: "/pages/sim-list",
    name: "SIM List",
    meta: { authRequired: true },
    component: () => import("./views/pages/sim/list-sim"),
  },
  {
    path: "/pages/user-provisioning",
    name: "User Provisioning",
    meta: { authRequired: true },
    component: () => import("./views/pages/mobileuser/user-provisioning"),
  },
  {
    path: "/pages/list-user",
    name: "List Users",
    meta: { authRequired: true },
    component: () => import("./views/pages/mobileuser/list-mobileuser"),
  },
  {
    path: "/pages/list-rssp-clients",
    name: "List RSSP Clients",
    meta: { authRequired: true },
    component: () => import("./views/pages/mobileuser/list-rssp-clients"),
  },
  {
    path: "/pages/self-care",
    name: "self-care",
    meta: {authRequired: true},
    component: () => import("./views/pages/self-care")
  },
  {
    path: "/pages/user-activation",
    name: "User Activation",
    meta: {authRequired: true},
    component: () => import("./views/pages/mobileuser/user-activation")
  },
  {
    path: "/pages/audit-logs",
    name: "Audit Logging",
    meta: {authRequired: true},
    component: () => import("./views/pages/audit-logs")
  },
  {
    path: "/pages/alauda-logs",
    name: "Alauda Logs",
    meta: {authRequired: true},
    component: () => import("./views/pages/alauda-logs")
  },
  {
    path: "/pages/ota-logs",
    name: "OTA Logs",
    meta: {authRequired: true},
    component: () => import("./views/pages/ota/ota-logs")
  },
  {
    path: "/pages/notification-logs",
    name: "Notification Logs",
    meta: {authRequired: true},
    component: () => import("./views/pages/notification-logs")
  },
  {
    path: "/pages/mss-logs",
    name: "MSS Logs",
    meta: {authRequired: true},
    component: () => import("./views/pages/mss-logs")
  },
  { path: "/pages/list-mssps",
    name: "MSSP Mesh",
    meta: {authRequired: true},
    component: () => import("./views/pages/list-mssps")
  },
  { path: "/pages/list-kiuru-servers",
    name: "Kiuru Server List",
    meta: {authRequired: true},
    component: () => import("./views/pages/server/list-kiuru-servers")
  },
  {
    path: "/pages/single-mssp",
    name: "Single MSSP",
    meta: {authRequired: true},
    component: () => import("./views/pages/single-mssp")
  },
  {
    path: "/pages/single-kiuru-server",
    name: "Single Kiuru Server",
    meta: {authRequired: true},
    component: () => import("./views/pages/server/single-kiuru-server")
  },
  {
    path: "/pages/ca-connections",
    name: "CA Connections",
    meta: {authRequired: true},
    component: () => import("./views/pages/ca-connections")
  },
  {
    path: "/pages/single-ca",
    name: "Single CA",
    meta: {authRequired: true},
    component: () => import("./views/pages/single-ca")
  },
  {
    path: "/pages/mreg-logs",
    name: "Mreg Logs",
    meta: {authRequired: true},
    component: () => import("./views/pages/mreg-logs")
  },
  {
    path: "/pages/pby-logs",
    name: "PBY Logs",
    meta: {authRequired: true},
    component: () => import("./views/pages/pby-logs")
  },
  {
    path: "/pages/ota-stats",
    name: "OTA Stats",
    meta: {authRequired: true},
    component: () => import("./views/pages/ota/ota-stats")
  },
  {
    path: "/pages/ota-config",
    name: "OTA Configuration",
    meta: {authRequired: true},
    component: () => import("./views/pages/ota/ota-config")
  },
  {
    path: "/no-access",
    name: "No Access",
    meta: {authRequired: false},
    component: () => import("./views/utility/no-access"),
  },
  {
    path: "/pages/create-ap",
    name: "Create AP",
    meta: {authRequired: true},
    component: () => import("./views/pages/ap/create-ap"),
  },
  {
    path: "/pages/list-ap",
    name: "List APs",
    meta: {authRequired: true},
    component: () => import("./views/pages/ap/list-ap"),
  },
  {
    path: "/pages/single-ap",
    name: "Single AP",
    meta: {authRequired: true},
    component: () => import("./views/pages/ap/single-ap"),
  },
  {
    path: "/pages/ap-export-upload",
    name: "Upload Datafile",
    meta: {authRequired: true},
    component: () => import("./views/pages/ap/datafile")
  },
  {
    path: "/pages/ap/upload-valimo-sp",
    name: "Upload Valimo SP",
    meta: { authRequired: true },
    component: () => import("./views/pages/ap/upload-valimo-sp"),
  },
  {
    path: "/pages/single-sim",
    name: "Single SIM",
    meta: {authRequired: true},
    component: () => import("./views/pages/sim/single-sim"),
  },
  {
    path: "/pages/single-mobileuser",
    name: "Single MobileUser",
    meta: {authRequired: true},
    component: () => import("./views/pages/mobileuser/single-mobileuser"),
  },
  {
    path: "/pages/cli-tools",
    name: "CLI Tools",
    meta: {authRequired: true},
    component: () => import("./views/pages/cli-tools"),
  },
  {
    path: "/pages/webuser/create",
    name: "Create Web User",
    meta: {authRequired: true},
    component: () => import("./views/pages/webmanageruser/create-user")
  },
  {
    path: "/pages/mobileuser/create-app",
    name: "Create App User",
    meta: {authRequired: true},
    component: () => import("./views/pages/mobileuser/app-provisioning")
  },
  {
    path: "/pages/webuser/list/all",
    name: "List Web Users",
    meta: {authRequired: true},
    component: () => import("./views/pages/webmanageruser/list-web-users")
  },
  {
    path: "/pages/user/profile",
    name: "User Profile",
    meta: {authRequired: true},
    component: () => import("./views/pages/profile/user-profile")
  },
  {
    path: "/pages/webuser/single",
    name: "Single Webuser",
    meta: {authRequired: true},
    component: () => import("./views/pages/webmanageruser/single-webuser")
  },
  {
    path: "/pages/musap-link",
    name: "MUSAP Link",
    meta: {authRequired: true},
    component: () => import("./views/pages/musap-link.vue")
  },
  {
    path: "/pages/ota-records",
    name: "OTA File Records",
    meta: {authRequired: true},
    component: () => import("./views/pages/ota/ota-records.vue")
  },
  {
    path: "/pages/ota-single-record",
    name: "Single OTA Record",
    meta: {authRequired: true},
    component: () => import("./views/pages/ota/ota-single-record.vue")
  },
  {
    path: "/pages/ap/services",
    name: "AP Services",
    meta: { authRequired: true },
    component: () => import("./views/pages/ap/list-ap-services.vue")
  },
  {
    path: "/pages/ap/single-service",
    name: "Service",
    meta: {authRequired: true},
    component: () => import("./views/pages/ap/single-ap-service"),
  },
  {
    path: "/pages/ap/service/new",
    name: "New service",
    meta: {authRequired: true},
    component: () => import("./views/pages/ap/new-service"),
  }

];
