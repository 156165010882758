import axios from "axios";
import swal from "sweetalert2-neutral";
import {createRouter as route, onBeforeRouteUpdate} from "vue-router";
import router from "@/router";

const TOKEN_KEY = "jwt";

class AuthService {

    /**
     * Authenticates with username/password
     * @param username
     * @param password
     * @param baseURL
     * @returns {Promise<AxiosResponse<any>>}
     */
    async authenticate(username, password, baseURL) {
        let url = baseURL + "login/password";
        let basic = btoa(username+":"+password);
        let headers =  {"Authorization": "Basic " + basic};
        return await axios.post(url, {}, {
            headers: headers,
            timeout: 120000
        }).then((response) => {
            let resp = JSON.parse(JSON.stringify(response.data));
            localStorage.setItem("roles",  resp["roles"]);
            localStorage.setItem("jwt",    resp["jwt"]);
            localStorage.setItem("fullname", resp["username"]);
            return resp;
        }).catch((error) => {
            return this.getJson(error);
        });
    }

    /**
     * Authenticates with MobileID
     * @param msisdn
     * @param baseURL
     * @returns {Promise<AxiosResponse<any>>}
     */
    async authenticateMobileID(msisdn, baseURL) {
        console.log("Trying to authenticate through MobileID with " + msisdn + " as msisdn to server: " + baseURL);
        let url = baseURL + "login/mobileid";
        return await axios.post(url, {msisdn: msisdn}, {
            timeout: 120000
        }).then((response) => {
            let resp = this.getJson(response);
            localStorage.setItem("roles", resp["roles"]);
            localStorage.setItem("jwt", resp["jwt"]);
            localStorage.setItem("msisdn", resp["msisdn"]);
            if (resp["givenname"] !== undefined && resp["surname"] !== undefined) {
                localStorage.setItem("fullname", resp["givenname"] + " " + resp["surname"]);
            }
            return resp;
        }).catch((error) => {
            return this.getJson(error);
        });
    }

    /**
     * Get roles of the logged in user
     * @returns {string[]}
     */
    getSavedRoles() {
        let roles = localStorage.getItem("roles");

        if (!roles) {
            return [];
        }
        return roles.split(",");
    }

    /**
     * Get MSISDN of the logged in user
     * @returns {string}
     */
    getSavedMsisdn() {
        return localStorage.getItem("msisdn");
    }

    /**
     * Get logged in user's full name (or username)
     * @returns {string}
     */
    getFullName() {
        return localStorage.getItem("fullname");
    }

    isMobileIdUser() {
        let msisdn = this.getSavedMsisdn();
        if (msisdn === null) return false;
        if (msisdn === undefined) return false;
        return true;
    }

    /**
     * Logs out user by deleting token from localStorage
     */
    logout() {
        console.log("Logging out");
        localStorage.removeItem(TOKEN_KEY);
        localStorage.removeItem("roles");
        localStorage.removeItem("msisdn");
        localStorage.removeItem("fullname");
    }

    confirmLogoutPopup() {
        return swal.fire({
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#556ee6',
            confirmButtonColor: '#f46a6a',
            confirmButtonText: 'Yes, logout!'
        }).then((result) => {
            if (result.isConfirmed) {
                this.logout();
                router.push("/mlogin");
            }
        });
    }

    /**
     * Gets current JWT from localStorage
     * @returns {any}
     */
    getCurrentJWT() {
        return JSON.parse(localStorage.getItem(TOKEN_KEY));
    }

    /**
     * Checks if there is a stored JWT
     * @returns {boolean}
     */
    isAuthenticated = () => {
        if (localStorage.getItem(TOKEN_KEY)){
            return true;
        }
        return false
    }


    getJson(message) {
        if (message.code == "ECONNABORTED") {
            return {"message": "EXPIRED_TRANSACTION", "details": "Login timed out", "success": false}
        }
        try {
            return JSON.parse(JSON.stringify(message.response.data));
        } catch (err) {
            try {
                return JSON.parse(JSON.stringify(message.data));
            } catch (err2) {
                console.log("Failed to parse");
                return {"message": "INTERNAL_ERROR", "details": "Unknown error", "success": false}
            }
        }
    }
}

export default new AuthService();
